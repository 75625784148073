import { axios } from 'libs/axios';

import { UserResponse } from '../types';
import { PROJECT_ID } from 'config';


export type SSOLoginCredentialsDTO = {
	code: string;
};

export const loginWithSSOCode = (data: SSOLoginCredentialsDTO): Promise<UserResponse> => {
	return axios.post(`/auth/sso/login/`, data);
};
