import { CircularProgress } from '@mui/material';
import MuiButton, { ButtonProps as MuiButtonProps } from '@mui/material/Button';
import React from 'react';
import { To } from 'react-router-dom';

interface ButtonProps extends MuiButtonProps {
	isLoading?: boolean;
	component?: React.ElementType;
	to?: To;
	target?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
	({ isLoading, startIcon, ...props }, ref) => {
		return (
			<MuiButton
				ref={ref}
				disableRipple
				disabled={isLoading}
				{...props}
				startIcon={isLoading ? <CircularProgress size={20} /> : startIcon}
			>
				{props.children}
			</MuiButton>
		);
	}
);
