import { axios } from 'libs/axios';

import { UserResponse } from '../types';
import { PROJECT_ID, ENVIRONMENT_ID } from 'config';


export type LoginCredentialsDTO = {
	email: string;
	password: string;
};

export const loginWithEmailAndPassword = (data: LoginCredentialsDTO): Promise<UserResponse> => {
	return axios.post(`/auth/login/`, {
		project_id: PROJECT_ID,
		environment_id: ENVIRONMENT_ID,
		...data
	});
};
