import { GlobalStyles as MUIGlobalStyles } from '@mui/material';
import React from 'react';

const GlobalStyles = () => {
	return (
		<MUIGlobalStyles
			styles={{
				html: { height: '100%' },
				body: {
					margin: 0,
					padding: 0,
					fontFamily: 'Gilroy-Light, Arial, Helvetica, sans-serif',
					lineHeight: 1,
				},
				'#root': {},
				a: {
					textDecoration: 'none',
					color: 'inherit',
				}
			}}
		/>
	);
};

export default GlobalStyles;
