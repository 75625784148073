import React, { useEffect } from 'react';

import { API_URL } from 'config';
import { useQueryClient } from 'libs/react-query';

type WebSocketContextValue = {};

const WebSocketContext = React.createContext<WebSocketContextValue>({} as WebSocketContextValue);

interface WebSocketProviderProps {
	children: React.ReactNode;
}

export const WebSocketProvider = ({ children }: WebSocketProviderProps) => {
	const queryClient = useQueryClient();

	useEffect(() => {
		const websocket = new WebSocket(`${API_URL.replace('http', 'ws')}/dashboard/ws/admin/`);

		websocket.onopen = () => {
			console.log('Websocket connected');
		};

		websocket.onmessage = (event) => {
			const data = JSON.parse(event.data);

			if (data?.command === 'exercise_job_update' && data?.exercise_id) {
				queryClient.invalidateQueries(['exercise', data.exercise_id]);
			}
		};

		return () => {
			websocket.close();
		};
	}, [queryClient]);

	return <WebSocketContext.Provider value={{}}>{children}</WebSocketContext.Provider>;
}

export const useWebsocket = () => {
	return React.useContext(WebSocketContext);
}

