import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import React from 'react';

import { useLocalStorage } from 'hooks';

import GlobalStyles from './globalStyles';
import { light, dark } from './themes';

export const darkScrollbar = () => ({
	'&::-webkit-scrollbar-track': {
		borderRadius: '8px',
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
	},
	'&::-webkit-scrollbar': {
		width: '8px',
		backgroundColor: 'rgba(0, 0, 0, 0.1)',
	},
	'&::-webkit-scrollbar-thumb': {
		borderRadius: '10px',
		backgroundColor: '#000',
	},
});

type ThemePalette = 'dark' | 'light';

type ThemeContextValue = {
	palette: ThemePalette;
	setPalette: (palette: ThemePalette) => void;
};

export const ThemeContext = React.createContext<ThemeContextValue>({} as ThemeContextValue);

export const ThemeProvider: React.FC = ({ children }) => {
	const { value: palette, setValue: setPalette } = useLocalStorage('theme', 'light');

	const theme = palette === 'dark' ? createTheme(dark) : createTheme(light);

	return (
		<ThemeContext.Provider
			value={{
				palette,
				setPalette,
			}}
		>
			<MuiThemeProvider theme={theme}>
				<CssBaseline />
				<GlobalStyles />

				{children}
			</MuiThemeProvider>
		</ThemeContext.Provider>
	);
};
