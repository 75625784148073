import { queryClient } from 'libs/react-query';
import { WebSocketProvider } from 'libs/webSocket';
import { ThemeProvider } from 'theme';
import {
	Loader,
	ConfirmationDialogProvider,
	FormExitDialogProvider,
} from 'components';
import { QueryClientProvider } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { LocaleProvider } from 'libs/locale';


export const AppProvider: React.FC = ({ children }) => {

  return (
    <Suspense fallback={<Loader fullscreen />}>
			<BrowserRouter>
				<QueryClientProvider client={queryClient}>
					<LocaleProvider>
						<ThemeProvider>
							<WebSocketProvider>
								<FormExitDialogProvider>
									<ConfirmationDialogProvider>
										{children}
									</ConfirmationDialogProvider>
								</FormExitDialogProvider>
							</WebSocketProvider>
						</ThemeProvider>
					</LocaleProvider>
				</QueryClientProvider>
			</BrowserRouter>
		</Suspense>
  );

}
