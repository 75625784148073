import BuildIcon from '@mui/icons-material/Build';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FitnessCenterIcon from '@mui/icons-material/FitnessCenter';
import HomeIcon from '@mui/icons-material/Home';
import TvIcon from '@mui/icons-material/Tv';
import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import { LinearProgress } from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { styled, useTheme, Theme, CSSObject } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useIsFetching } from '@tanstack/react-query';
import React, { Suspense } from 'react';
import {
	Outlet,
	Link,
	useLocation,
	useResolvedPath,
	useMatch,
	useNavigate,
} from 'react-router-dom';

import logoImage from 'assets/images/small_logo.png';
import { Button, Loader } from 'components';
import { useAuthorization } from 'libs/auth';

import { AppLayoutProvider } from './AppLayoutProvider';
import { ErrorBoundary } from './error-boundary';
import { UserMenu } from './UserMenu';

const drawerWidth = 240;

interface ITab {
	label: string;
	path: string;
	icon: any;
}

type TabLinkProps = {
	label: string;
	icon: React.ReactNode;
	path: string;
	open: boolean;
};

const TabLink = ({ label, icon, path, open }: TabLinkProps) => {
	const resolved = useResolvedPath(path);
	const match = useMatch({ path: `${resolved.pathname}/*`, end: true });

	return (
		<Link to={path}>
			<ListItem
				key={label}
				disablePadding
				selected={!!match}
				sx={{
					display: 'block',
					borderTopRightRadius: '4px',
					borderBottomRightRadius: '4px',
					opacity: match ? 1 : 0.6,
				}}
			>
				<ListItemButton
					sx={{
						minHeight: 48,
						justifyContent: open ? 'initial' : 'center',
						px: 2.5,
					}}
				>
					<ListItemIcon
						sx={{
							minWidth: 0,
							mr: open ? 3 : 'auto',
							justifyContent: 'center',
						}}
					>
						{icon}
					</ListItemIcon>
					<ListItemText sx={{ opacity: open ? 1 : 0 }}>
						<Typography variant="h5">{label}</Typography>
					</ListItemText>
				</ListItemButton>
			</ListItem>
		</Link>
	);
};

const openedMixin = (theme: Theme): CSSObject => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
	backgroundColor: 'transparent',
	border: 'none',
});

const closedMixin = (theme: Theme): CSSObject => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
	backgroundColor: 'transparent',
	border: 'none',
});

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	backgroundColor: 'transparent',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

export const AppLayout = () => {
	const headerRef = React.useRef<HTMLDivElement>(null);
	const backButtonRef = React.useRef<HTMLDivElement>(null);
	const footerRef = React.useRef<HTMLDivElement>(null);

	const [open, setOpen] = React.useState(true);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const isFetching = useIsFetching();

	const { checkAccess } = useAuthorization();

	const tabs = [
		{ label: 'Home', icon: <HomeIcon />, path: '/' },
		checkAccess({
			resource: "feature",
			action: "list"
		}) && {
			label: 'Features',
			path: '/features',
			icon: <BuildIcon />,
		},
		checkAccess({
			resource: "exercise-pool",
			action: "list"
		}) && {
			label: 'Exercises',
			path: '/exercises',
			icon: <FitnessCenterIcon />,
		},
		checkAccess({
			resource: "channel",
			action: "list"
		}) && { label: 'Channels', path: '/channels', icon: <TvIcon /> },
	].filter(Boolean) as ITab[];

	const navigate = useNavigate();

	const navigateHome = () => {
		navigate('/', { replace: true });
	};

	return (
		<Box
			sx={{
				display: 'flex',
			}}
		>
			<Drawer variant="permanent" open={open}>
				<Link to="/">
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 2,
							m: 2,
							height: 36,
						}}
					>
						<img
							src={logoImage}
							alt=""
							style={{
								height: '100%',
								maxWidth: '36px',
							}}
						/>

						<Typography
							variant="h5"
							sx={{
								opacity: open ? 1 : 0,
							}}
						>
							Manage
						</Typography>
					</Box>
				</Link>

				<List>
					{tabs.map((tab, index) => (
						<TabLink key={tab.path} label={tab.label} icon={tab.icon} path={tab.path} open={open} />
					))}
				</List>

				<Box mt={3} ml={1}>
					<Button
						variant="outlined"
						onClick={open ? handleDrawerClose : handleDrawerOpen}
						sx={{
							minWidth: 'auto',
						}}
					>
						{open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
					</Button>
				</Box>
			</Drawer>

			<Box
				sx={{
					flexGrow: 1,
					overflowX: 'clip',
				}}
			>
				<Box
					sx={{
						position: 'absolute',
						top: 0,
						width: '100%',
						right: 0,
					}}
				>
					{isFetching > 0 &&
						<LinearProgress />
					}
				</Box>

				<Box
					component="main"
					sx={{
						minHeight: 'calc(100vh - 64px - 1rem)',
						p: 3,
					}}
				>
					<Box
						ref={headerRef}
						sx={{
							position: 'sticky',
							top: 0,
							zIndex: 1400,
						}}
					/>

					<Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
						<Box ref={backButtonRef} />

						<UserMenu />
					</Box>

					<AppLayoutProvider context={{ headerRef, backButtonRef, footerRef }}>
						<ErrorBoundary onBack={navigateHome}>
							<Suspense fallback={<Loader />}>
								<Outlet />
							</Suspense>
						</ErrorBoundary>
					</AppLayoutProvider>
				</Box>

				<Box
					ref={footerRef}
					sx={{
						position: 'sticky',
						bottom: 0,
						zIndex: 10,
					}}
				/>
			</Box>
		</Box>
	);
};
