import * as FullStory from '@fullstory/browser';
import {default as legacyRouter} from '@kemtai/components/dist/router';

// import { initMocks } from 'test/server';

const configureThirdParty = () => {
	if (process.env.REACT_APP_ENV !== 'development') {
		FullStory.init({ orgId: 'T7HM5' });
	}
};

const initApp = () => {
	legacyRouter.disable();
	// initMocks();
	configureThirdParty();
};

export default initApp;
