import { Typography, Menu, MenuItem, Avatar, Switch, FormControlLabel } from '@mui/material';

import { Button } from 'components';
import { useAnchorElement, useTheme } from 'hooks';
import { useUser, useLogout } from 'libs/auth';

export const UserMenu = () => {
	const user = useUser();
	const logout = useLogout();

	const [anchorEl, onClick, handleClose] = useAnchorElement<HTMLButtonElement>();

	const { palette, setPalette } = useTheme();

	const onDarkModeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setPalette(event.target.checked ? 'dark' : 'light');
	};

	return (
		<>
			<Button
				onClick={onClick}
				startIcon={
					<Avatar
						sx={{
							width: 34,
							height: 34,
							'&.MuiAvatar-root': {
								backgroundColor: '#90caf9',
							},
						}}
					>
						{user.data?.first_name?.slice(0, 1)}
					</Avatar>
				}
			>
				<Typography>{user.data?.email}</Typography>
			</Button>
			<Menu
				anchorEl={anchorEl}
				open={Boolean(anchorEl)}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: 'top',
					horizontal: 'right',
				}}
				sx={{
					'& .MuiPaper-root': {
						minWidth: 180,
					},
				}}
			>
				<MenuItem onClick={() => logout.mutate({})}>Logout</MenuItem>
				<MenuItem>
					<FormControlLabel
						control={<Switch checked={palette === 'dark'} onChange={onDarkModeChange} />}
						label="Enable dark mode"
					/>
				</MenuItem>
			</Menu>
		</>
	);
};
