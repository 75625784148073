import Axios, { AxiosRequestConfig } from 'axios';
import { API_URL } from 'config';
import { storage } from 'libs/storage';
import { refreshAccessToken } from 'features/auth/api';
import { isTokenExpired } from 'utils/jwt';
import { logoutFn } from 'libs/auth';
import { useNotificationStore } from 'stores/notifications';


export const axios = Axios.create({
  baseURL: API_URL,
});

export const jwtManageAxios = Axios.create({
  baseURL: API_URL,
});

type RequestsQueue = {
  resolve: (value?: unknown) => void;
  reject: (reason?: unknown) => void;
}[];

let isRefreshing = false;
let queue: RequestsQueue = [];

export async function authRequestInterceptor(config: AxiosRequestConfig) {
  const refreshToken = storage.getRefreshToken();

  if (!refreshToken) {
    return config;
  }

  if (isRefreshing) {
    return new Promise((resolve, reject) => {
      queue.push({ resolve, reject })
    })
    .then((token) => {
      config.headers!.Authorization = `Bearer ${token}`;
      return config
    })
    .catch(Promise.reject);
  }

  let token = storage.getAccessToken();

  if (isTokenExpired(token)) {
    try {
      isRefreshing = true;
      const data = await refreshAccessToken({ refresh: refreshToken });
      token = data.access;
      storage.setAccessToken(token);
      queue.forEach((p) => {
        p.resolve(token);
      });

      queue = [];
    } catch (error) {
      logoutFn();
    } finally {
      isRefreshing = false
    }
  }

  if (token) {
    config.headers!.Authorization = `Bearer ${token}`;
  }

  return config
}

axios.interceptors.request.use(authRequestInterceptor);

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    if (error.response.status === 401 && error.response.data?.code !== "token_not_valid") {
      logoutFn();
    }

    if (error.response.status >= 500) {
      useNotificationStore.getState().addNotification({
        type: "error",
        title: "Error",
        message: "Something went wrong. Please try again.",
      });
    }

    return Promise.reject(error);
  }
);

jwtManageAxios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    useNotificationStore.getState().addNotification({
      type: "error",
      title: "Error",
      message: "Something went wrong. Please try again.",
    });

    return Promise.reject(error);
  }
);
