import * as FullStory from '@fullstory/browser';
import ErrorIcon from '@mui/icons-material/Error';
import { Box, Typography } from '@mui/material';
import React from 'react';

import { Button } from 'components';
import { axios } from 'libs/axios';

type ErrorBoundaryState = {
	error: Error | null;
};

const initialState: ErrorBoundaryState = {
	error: null,
};

type ErrorBoundaryProps = {
	onBack: () => void;
};

export class ErrorBoundary extends React.Component<
	React.PropsWithChildren<ErrorBoundaryProps>,
	ErrorBoundaryState
> {
	static getDerivedStateFromError(error: Error) {
		return { error };
	}

	state = initialState;

	resetErrorBoundary = () => {
		this.props.onBack();
		this.reset();
	};

	reset() {
		this.setState(initialState);
	}

	componentDidCatch(error: Error, info: React.ErrorInfo) {
		if (process.env.NODE_ENV === 'production') {
			axios.post(`dashboard/bug-report/`, {
				stack: error.stack,
				component_stack: info.componentStack,
				story_url: FullStory.getCurrentSessionURL(true),
			});
		}
	}

	render() {
		const { error } = this.state;

		if (error !== null) {
			return (
				<Box
					display="flex"
					alignItems="center"
					justifyContent="center"
					flexDirection="column"
					gap={3}
				>
					<ErrorIcon
						sx={{
							fontSize: 200,
							color: 'error.light',
						}}
					/>

					<Box>
						<Typography variant="h4" align="center">
							We’ve encountered an unexpected error
						</Typography>

						<Typography align="center">Try to refresh the page or navigate home.</Typography>
					</Box>

					<Box display="flex" gap={2}>
						<Button variant="contained" onClick={this.resetErrorBoundary}>
							Go Home
						</Button>

						<Button variant="contained" onClick={() => window.location.reload()}>
							Refresh
						</Button>
					</Box>
				</Box>
			);
		}

		return this.props.children;
	}
}
