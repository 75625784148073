import { axios } from 'libs/axios';

import { IPolicyRule } from '@kemtai/auth';
import { PROJECT_ID } from 'config';


export const getPolicy = (): Promise<IPolicyRule[]> => {
	return axios.get(`/auth/policy/`, {
		params: {
			project_id: PROJECT_ID
		}
	});
};
