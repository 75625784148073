import React from 'react';

interface IAppLayoutContext {
	headerRef: React.RefObject<HTMLDivElement>;
	backButtonRef: React.RefObject<HTMLDivElement>;
	footerRef: React.RefObject<HTMLDivElement>;
}

export const AppLayoutContext = React.createContext<IAppLayoutContext>({} as IAppLayoutContext);

type AppLayoutProps = {
	context: IAppLayoutContext;
};

export const AppLayoutProvider: React.FC<AppLayoutProps> = ({ context, children }) => {
	return <AppLayoutContext.Provider value={context}>{children}</AppLayoutContext.Provider>;
};
