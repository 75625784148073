import React, { lazy } from 'react';
import type { RouteObject } from 'react-router-dom';
import { Navigate, useRoutes } from 'react-router-dom';
import { AppLayout, Loader } from 'components';
import { AuthLoader } from 'libs/auth';

const Home = lazy(() => import('features/home'));
const Auth = lazy(() => import('features/auth'));
const Features = lazy(() => import('features/features'));
const Exercises = lazy(() => import('features/exercises'));
const Channels = lazy(() => import('features/channels'));

export const publicRoutes: RouteObject[] = [
	{
		path: '/',
		element: <Navigate to="auth/login" />,
	},
	{
		path: '/auth/*',
		element: <Auth />,
	},
	{
		path: '*',
		element: <Navigate to="/" />,
	},
];

export const protectedRoutes: RouteObject[] = [
	{
		path: '/',
		element: <AppLayout />,
		children: [
			{
				index: true,
				element: <Home />,
			},
			{
				path: '/features/*',
				element: <Features />,
			},
			{
				path: '/exercises/*',
				element: <Exercises />,
			},
			{
				path: '/channels/*',
				element: <Channels />,
			},
		],
	},
	{
		path: '*',
		element: <Navigate to="/" />,
	},
];

const Routes = () => {
	const protectedElement = useRoutes(protectedRoutes) as JSX.Element;
	const publicElement = useRoutes(publicRoutes) as JSX.Element;

	return (
		<AuthLoader
			renderLoading={() => <Loader fullscreen title="Authenticating" />}
			renderUnauthenticated={() => publicElement}
		>
			{protectedElement}
		</AuthLoader>
	);
};

export default Routes;
