import {
	Dialog,
	DialogTitle,
	DialogActions,
	DialogContent,
	DialogContentText,
} from '@mui/material';
import React, { useState, useRef } from 'react';

import { Button } from 'components';

export type ConfirmationDialogOptions = {
	title: string;
	subtitle: string;
	confirmLabel?: string;
	confirmType?: 'default' | 'error';
	declineLabel?: string;
};

type ConfirmationDialogContextValue = {
	openDialog: (options: ConfirmationDialogOptions) => Promise<boolean>;
};

export const ConfirmationDialogContext = React.createContext<ConfirmationDialogContextValue>(
	{} as ConfirmationDialogContextValue
);

export const ConfirmationDialogProvider: React.FC = ({ children }) => {
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState<ConfirmationDialogOptions>();
	const promiseResolver = useRef<(value: boolean) => void>();

	const openDialog = (options: ConfirmationDialogOptions) => {
		setOpen(true);
		setOptions(options);

		return new Promise<boolean>((resolve) => {
			promiseResolver.current = resolve;
		});
	};

	const closeDialog = () => {
		setOpen(false);
		setOptions(undefined);
	};

	const onConfirmClick = () => {
		if (promiseResolver.current) {
			promiseResolver.current(true);
		}

		closeDialog();
	};

	const onDeclineClick = () => {
		if (promiseResolver.current) {
			promiseResolver.current(false);
		}

		closeDialog();
	};

	const value = {
		openDialog,
	};

	return (
		<ConfirmationDialogContext.Provider value={value}>
			<Dialog open={open}>
				<DialogTitle>{options?.title}</DialogTitle>

				<DialogContent>
					<DialogContentText>{options?.subtitle}</DialogContentText>
				</DialogContent>

				<DialogActions>
					<Button variant="outlined" onClick={onDeclineClick}>
						{options?.declineLabel}
					</Button>
					<Button
						variant="contained"
						onClick={onConfirmClick}
						color={options?.confirmType === 'error' ? 'error' : 'primary'}
					>
						{options?.confirmLabel}
					</Button>
				</DialogActions>
			</Dialog>

			{children}
		</ConfirmationDialogContext.Provider>
	);
};
