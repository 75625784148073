import { createTheme, ThemeOptions } from '@mui/material/styles';

const family = ["'Open Sans'", 'Verdana', 'Geneva', 'Tahoma', 'sans-serif'].join(', ');

const { typography, palette, shadows, breakpoints } = createTheme({
	typography: { fontSize: 14 },
});

const base = {
	components: {
		MuiAppBar: {
			styleOverrides: {
				root: {
					boxShadow: shadows[1],
				},
			},
		},
		MuiToolbar: {
			styleOverrides: {
				root: {
					minHeight: 64,
				},
				regular: {
					[breakpoints.up('xs')]: {
						minHeight: 64,
					},
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					textTransform: 'none',
					[breakpoints.up('xs')]: {
						minWidth: 120,
					},
				},
				textColorInherit: {
					opacity: 1,
				},
			},
		},
		MuiTabs: {
			styleOverrides: {
				root: {
					minHeight: 60,
				},
				flexContainer: {
					height: '100%',
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					':hover': {
						backgroundColor: 'rgba(0, 0, 0, 0.03)',
					},
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				elevation1: {
					boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
				},
				elevation2: {
					boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)',
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					...typography.body1,
					borderBottom: `1px solid ${palette.divider}`,
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				gutterBottom: {
					marginBottom: 8,
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				sizeSmall: {
					lineHeight: 1.3,
				},
			},
		},
		MuiTextField: {
			defaultProps: {
				variant: 'outlined',
				size: 'small',
			},
		},
		MuiButton: {
			defaultProps: {
				disableRipple: true,
				disableFocusRipple: true,
			},
		},
		MuiTooltip: {
			defaultProps: {
				arrow: true,
			},
		},
	},
	typography: {
		fontSize: 14,
		fontFamily: family,
		h1: {
			fontFamily: family,
			fontWeight: 600,
			fontSize: typography.pxToRem(45),
			lineHeight: typography.pxToRem(50),
		},
		h2: {
			fontFamily: family,
			fontWeight: 600,
			fontSize: typography.pxToRem(29),
			lineHeight: typography.pxToRem(32),
		},
		h3: {
			fontFamily: family,
			fontWeight: 600,
			fontSize: typography.pxToRem(24),
			lineHeight: typography.pxToRem(28),
		},
		h4: {
			fontFamily: family,
			fontWeight: 600,
			fontSize: typography.pxToRem(20),
			lineHeight: typography.pxToRem(24),
		},
		h5: {
			fontFamily: family,
			fontWeight: 600,
			fontSize: typography.pxToRem(16),
			lineHeight: typography.pxToRem(20),
		},
		h6: {
			fontFamily: family,
			fontWeight: 600,
			fontSize: typography.pxToRem(14),
			lineHeight: typography.pxToRem(20),
		},
		subtitle1: {
			fontFamily: family,
			fontSize: typography.pxToRem(16),
			lineHeight: typography.pxToRem(25),
		},
		subtitle2: {
			fontFamily: family,
			fontWeight: 400,
			fontSize: typography.pxToRem(14),
			lineHeight: typography.pxToRem(21),
		},
		body1: {
			fontFamily: family,
			fontSize: '1rem',
			lineHeight: typography.pxToRem(21),
		},
		body2: {
			fontFamily: family,
			fontSize: typography.pxToRem(12),
			lineHeight: typography.pxToRem(20),
		},
		button: {
			fontFamily: family,
			fontSize: '1rem',
		},
		caption: {
			fontFamily: family,
			fontSize: typography.pxToRem(12),
			lineHeight: typography.pxToRem(13),
		},
		overline: {
			fontFamily: family,
			fontSize: typography.pxToRem(12),
			fontWeight: 500,
			textTransform: 'uppercase',
		},
	},
};

export const dark = {
	...base,
	palette: {
		mode: 'dark',
		background: {
			default: 'rgb(27, 28, 34)',
		},
	},
	components: {
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundColor: '#27282d',
				},
			},
		},
	},
} as ThemeOptions;

export const light = {
	...base,
	palette: {
		mode: 'light',
		background: {
			default: '#EFF5F8',
		},
	},
} as ThemeOptions;
