import { Notifications } from 'components';
import initApp from './initApp';
import Routes from './routes';
import { AppProvider } from './provider';


initApp();

const App = () => {
	return (
		<AppProvider>
			<Notifications />

			<Routes />
		</AppProvider>
	);
};

export default App;
