import React from 'react';
import { configureAuth } from '@kemtai/auth';
import {
	loginWithEmailAndPassword,
	getUser,
	UserResponse,
	LoginCredentialsDTO,
	IUser,
	loginWithSSOCode,
	SSOLoginCredentialsDTO,
	getPolicy,
} from 'features/auth';
import { storage } from 'libs/storage';
import { PROJECT_ID, API_URL } from 'config';


async function handleUserResponse(data: UserResponse) {
	const { access, refresh, user } = data;
	storage.setAccessToken(access);
	storage.setRefreshToken(refresh);

	return user;
}

async function userFn() {
	if (storage.getAccessToken()) {
	  const user = await getUser();
	  return user ?? null;
	}

	return null;
}

async function loginFn(data: LoginCredentialsDTO) {
  const response = await loginWithEmailAndPassword(data);
  const user = await handleUserResponse(response);
  return user;
}

async function ssoCallbackFn(data: SSOLoginCredentialsDTO) {
	const response = await loginWithSSOCode(data);
	const user = await handleUserResponse(response);
	return user;
}

export async function logoutFn() {
	storage.clearAccessToken();
	storage.clearRefreshToken();
	window.location.assign(window.location.origin as unknown as string);
}

async function policyFn() {
	const data = await getPolicy();
	return data;
}

function userRolesFn(user: IUser) {
	const userRoles = user.roles.filter((userRole) => userRole.tenant === "default").map((userRole) => userRole.role);
	return userRoles;
}

export const {
	useUser,
	useIsAuthenticated,
	useLogin,
	useSSOCallback,
	useRegister,
	useLogout,
	AuthLoader,
	useAuthorization,
	Authorization
} = configureAuth({
  userFn,
	loginFn,
	ssoCallbackFn,
	logoutFn,
	policyFn,
	userRolesFn,
});
