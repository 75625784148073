import { nanoid } from 'nanoid';
import { create } from 'zustand';

import { INotification } from 'types';


type NotificationsStore = {
	notifications: INotification[];
	addNotification: (notification: Omit<INotification, 'id'>) => void;
	dismissNotification: (id: string) => void;
};

export const useNotificationStore = create<NotificationsStore>((set) => ({
	notifications: [],
	addNotification: (notification) =>
		set((state) => ({
			notifications: [...state.notifications, { id: nanoid(), ...notification }],
		})),
	dismissNotification: (id) =>
		set((state) => ({
			notifications: state.notifications.filter((notification) => notification.id !== id),
		})),
}));
