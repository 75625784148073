import CloseIcon from '@mui/icons-material/Close';
import { SnackbarContent, Alert, Box, Typography, IconButton, Collapse } from '@mui/material';
import { useState, useEffect } from 'react';

import { useTimeout } from 'hooks';
import { INotification } from 'types';

type NotificationProps = {
	notification: INotification;
	onDismiss: (id: string) => void;
};

export const Notification = ({ notification, onDismiss }: NotificationProps) => {
	const timeout = useTimeout();

	useEffect(() => {
		timeout.set(
			() => {
				onDismiss(notification.id);
			},
			5000,
			`notification-${notification.id}`
		);

		return () => {
			timeout.clear(`notification-${notification.id}`);
		};
	}, []);

	const onMouseEnter = () => {
		timeout.pause(`notification-${notification.id}`);
	};

	const onMouseLeave = () => {
		timeout.resume(`notification-${notification.id}`);
	};

	return (
		<Alert
			onClose={() => onDismiss(notification.id)}
			severity={notification.type}
			variant="filled"
			elevation={0}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
			sx={{
				width: 'inherit',
			}}
		>
			<Typography variant="h5">{notification.title}</Typography>

			<Typography variant="body1">{notification.message}</Typography>
		</Alert>
	);
};
